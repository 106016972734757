import React, { useEffect, useMemo, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { domain, Context } from '../../config';

import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { getHome } from '../../store/ducks/home/actions';
import { LayoutHomeState } from '../../store/ducks/home/types';
import { State } from '../../types';

const HomeRu = () => {
    const { siteDomain, locale } = useContext(Context);
    const dispatch = useDispatch();
    const home: LayoutHomeState = useSelector(
        (state: State) => state.home.data
    );
    const status: string = useSelector((state: State) => state.home.status);
    useEffect(() => {
        if (status === 'IDLE') {
            dispatch(getHome(locale));
        }
    }, []);

    const [browser, setBrowser] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (window.innerWidth > 767) {
                setBrowser(false);
            } else {
                setBrowser(true);
            }
            const resizeThrottler = () => {
                if (window.innerWidth < 767) {
                    setBrowser(true);
                } else {
                    setBrowser(false);
                }
            };

            window.addEventListener('resize', resizeThrottler, false);
        }
    }, []);
    const videoStr = `
        <img
            src="img/first-screen-bg-ru.jpg"
            srcSet="img/first-screen-bg-ru.jpg 1x, img/first-screen-bg-ru@2x.jpg 2x"
            alt="img"
        ></img>
        <video
            src="${
                browser
                    ? 'img/overbest_rus_mobile_compressed_2.mp4'
                    : 'img/overbest_rus_compressed.mp4'
            }"
            autoPlay="true"
            preload="true"
            playsinline="true"
            loop="true"
            muted
        ></video>
    `;
    return (
        <>
            {home && (
                <>
                    <Helmet>
                        <title>{home.title}</title>
                        <meta property="og:title" content={`${home.title}`} />
                        <meta
                            property="twitter:title"
                            content={`${home.title}`}
                        />
                        <meta
                            name="description"
                            content={`${home.description}`}
                        />
                        <meta
                            property="og:description"
                            content={`${home.description}`}
                        />
                        <meta
                            property="twitter:description"
                            content={`${home.description}`}
                        />
                        <meta
                            property="og:image"
                            content={`${siteDomain}/img/overbest_og_ru.png`}
                        />
                        <meta property="og:url" content={`${siteDomain}/`} />
                        <meta
                            name="twitter:card"
                            content="summary_large_image"
                        />
                    </Helmet>
                    <main className="page-ru">
                        <section className="first-screen page-ru__first-screen">
                            <div
                                className="first-screen__bg"
                                dangerouslySetInnerHTML={{ __html: videoStr }}
                            ></div>
                            <div className="first-screen__container">
                                <h1
                                    className="first-screen__title title title--white"
                                    dangerouslySetInnerHTML={{
                                        __html: home.title,
                                    }}
                                />
                                <p
                                    className="first-screen__text text text--white"
                                    dangerouslySetInnerHTML={{
                                        __html: home.description,
                                    }}
                                />
                                {home.button && (
                                    <a
                                        href={home.button.Link}
                                        className="first-screen__link button button--white button--large"
                                    >
                                        {home.button.Title}
                                    </a>
                                )}
                            </div>
                        </section>
                        <section id="our_focus" className="our page-ru__our">
                            <div className="our__container">
                                <h2 className="our__title title title--black">
                                    {home.subTitle}
                                </h2>
                                {home.servicesList &&
                                    home.servicesList.length > 0 && (
                                        <ul className="our__list">
                                            {home.servicesList.map(item => (
                                                <li
                                                    key={item.key}
                                                    className="our__item"
                                                >
                                                    <div className="our__img">
                                                        <img
                                                            src={`${domain}${item.icon}`}
                                                            alt={item.title}
                                                        />
                                                    </div>
                                                    <h3
                                                        className="our__name smallTitle smallTitle--black"
                                                        dangerouslySetInnerHTML={{
                                                            __html: item.title,
                                                        }}
                                                    />
                                                    <p
                                                        className="our__text mediumText mediumText--black"
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                item.description,
                                                        }}
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                            </div>
                        </section>
                        <section
                            id="who_we_are"
                            className="staff page-ru__staff"
                        >
                            <div className="staff__container">
                                {home.whoWeAre && (
                                    <h2 className="staff__title title title--white">
                                        {home.whoWeAre.Title}
                                    </h2>
                                )}
                                {home.whoWeAre && (
                                    <p
                                        className="staff__text text text--white"
                                        dangerouslySetInnerHTML={{
                                            __html: home.whoWeAre.Description,
                                        }}
                                    />
                                )}
                            </div>
                            <div className="staff__container">
                                {home.whoWeAreList &&
                                    home.whoWeAreList.length > 0 && (
                                        <ul className="staff__list">
                                            {home.whoWeAreList.map(item => (
                                                <li
                                                    key={item.key}
                                                    className="staff__item"
                                                >
                                                    <div className="staff__item-img">
                                                        <img
                                                            src={`${domain}${item.photo}`}
                                                            alt={item.name}
                                                        />
                                                    </div>
                                                    <h3 className="staff__item-title smallTitle smallTitle--white">
                                                        {item.name}
                                                    </h3>
                                                    <span className="staff__item-position text mediumText text--white">
                                                        {item.position}
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                            </div>
                        </section>
                        {(home.openPositionsList &&
                            home.openPositionsList.length === 0 && (
                                <section
                                    id="careers"
                                    className="join page-careers__join"
                                >
                                    <div className="join__container">
                                        <h2
                                            className="join__title title title--w"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    home.openPositions.Title,
                                            }}
                                        ></h2>
                                        <p
                                            className="join__text text text--black"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    home.openPositions
                                                        .Description,
                                            }}
                                        ></p>
                                        {home.openPositions && (
                                            <a
                                                href={
                                                    home.openPositions.LinkUrl
                                                }
                                                className="button button--large button--white join__btn"
                                            >
                                                {home.openPositions.LinkCaption}
                                            </a>
                                        )}
                                    </div>
                                </section>
                            )) || (
                            <section
                                id="careers"
                                className="join page-careers__join"
                            >
                                <div className="join__container">
                                    <h2 className="join__title join__title--left title">
                                        {home.openPositionsTitle}
                                    </h2>
                                    <ul className="join__list">
                                        {home.openPositionsList &&
                                            home.openPositionsList.map(item => (
                                                <li
                                                    key={item.key}
                                                    className="join__item"
                                                >
                                                    <span className="join__city mediumText">
                                                        {item.location}
                                                    </span>
                                                    <h4 className="join__name">
                                                        {item.title}
                                                    </h4>
                                                    <p
                                                        className="join__text mediumText"
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                item.description,
                                                        }}
                                                    />
                                                    <a
                                                        href={item.link}
                                                        className="button button--medium join__btn"
                                                    >
                                                        {item.labelLink}
                                                    </a>
                                                </li>
                                            ))}
                                    </ul>
                                    <h2 className="join__title title">
                                        {home.openPositionsNotFound &&
                                            home.openPositionsNotFound.Title}
                                    </h2>
                                    <p className="join__text text text--black">
                                        {home.openPositionsNotFound &&
                                            home.openPositionsNotFound
                                                .Description}
                                    </p>
                                    {home.openPositionsNotFound && (
                                        <a
                                            href={
                                                home.openPositionsNotFound
                                                    .LinkUrl
                                            }
                                            className="button button--large button--white join__btn"
                                        >
                                            {
                                                home.openPositionsNotFound
                                                    .LinkCaption
                                            }
                                        </a>
                                    )}
                                </div>
                            </section>
                        )}
                    </main>
                </>
            )}
        </>
    );
};

export default HomeRu;
